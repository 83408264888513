<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader :title="id ? 'Update Service' : 'Create Service'" />
        <div>
          <md-button :to="{ name: 'Handyman Services' }" class="md-primary">
            Back
          </md-button>
        </div>
      </div>

      <div>
        <form novalidate class="md-layout" @submit.prevent="validateService">
          <md-field :class="getValidationClass('title')">
            <label>Title</label>
            <md-input v-model="form.title" required></md-input>
            <span class="md-error" v-if="!$v.form.title.required"
              >Title is required</span
            >
          </md-field>

          <md-field v-if="metadata">
            <label for="parent_id">Parent Service</label>
            <md-select v-model="form.parent_id" name="parent_id" id="parent_id">
              <md-option value="" disabled>Select Parent Service</md-option>
              <md-option
                v-for="(parent_service, id) in metadata.parent_services"
                :key="parent_service"
                :value="id"
                >{{ parent_service }}</md-option
              >
            </md-select>
          </md-field>

          <md-field>
            <label>Position</label>
            <md-input v-model="form.position" required type="number"></md-input>
          </md-field>

          <div style="width: 100%">
            <md-checkbox v-model="form.featured">Featured </md-checkbox>
            <md-checkbox v-model="form.status">Visibility </md-checkbox>
          </div>
          <br />

          <mdc-button :loading="loading">{{
            id ? "Update Service" : "Create Service"
          }}</mdc-button>
        </form>
      </div>
    </md-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("handyman");

export default {
  mixins: [validationMixin],
  data: () => ({
    form: {
      title: "",
      status: true,
      featured: false,
    },
    loading: false,
    metadata: null,
  }),
  validations: {
    form: {
      title: {
        required,
      },
    },
  },
  methods: {
    ...mapActions([
      "createHandymanService",
      "updateHandymanService",
      "getHandymanServiceData",
      "handymanService",
    ]),
    async validateService() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = { ...this.form };

        data.featured = data.featured ? 1 : 0;
        data.status = data.status ? "live" : "draft";

        try {
          this.loading = true;
          let call = this.id
            ? this.updateHandymanService(data)
            : this.createHandymanService(data);
          let msg = this.id ? "Service Updated" : "Service Created";
          await call;
          this.toast("success", msg);
          this.$router.push({ name: "Handyman Services" });
          this.loading = false;
        } catch (err) {
          console.log(err);
          this.loading = false;
          this.toast("error", "Opps, Something went wrong ");
        }
      }
    },
    async getService() {
      if (this.id) {
        let { service } = await this.handymanService(this.id);

        this.form = {
          title: service.title,
          status: service.status == "live" ? true : false,
          featured: service.featured == 1 ? true : false,
          parent_id: service.parent_id,
          position: service.position,
          id: service.id,
        };
      }
    },
    async getData() {
      this.fetching = true;
      const res = await this.getHandymanServiceData();

      this.fetching = false;
      this.metadata = res;
    },
  },
  mounted() {
    this.getData();
    this.getService();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped lang="scss">
.feature-attributes {
  width: 100%;
  .wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .val {
      flex: 1;
    }

    .attr {
      width: 25%;
    }
  }
}
</style>
